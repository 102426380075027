import React from 'react';
import ReactDOM from 'react-dom';
import Centered from '../components/centered';

import aboutBackground from '../images/about.JPG';
import behindGlass from '../images/life/behindGlass.JPG';
import muestraSolidaria from '../images/life/muestraSolidaria.jpg';
import brendaFridman from '../images/life/brendaFridman.jpg';
import modusOperandi from '../images/life/modusOperandi.jpg';
import galeria3 from '../images/life/galeria3.JPG';
import galeria4 from '../images/life/galeria4.jpg';
import galeria5 from '../images/life/galeria5.jpg';
import galeria6 from '../images/life/galeria6.jpg';
import galeria7 from '../images/life/galeria7.jpg';
import books from '../images/life/books.jpg';
import brendaPainting from '../images/life/brendaPainting.JPG';
import brendaPainting2 from '../images/life/brendaPainting2.JPG';
import brendaPainting3 from '../images/life/brendaPainting3.JPG';
import details1 from '../images/life/details1.jpg';
import details2 from '../images/life/details2.jpg';
import details3 from '../images/life/details3.jpg';
import details4 from '../images/life/details4.jpg';
import details5 from '../images/life/details5.jpg';
import details6 from '../images/life/details6.jpg';
import details8 from '../images/life/details8.jpg';
import galeria1 from '../images/life/galeria1.JPG';
import galeria2 from '../images/life/galeria2.JPG';
import impresionDigital from '../images/life/impresionDigital.jpg';
import splitPainting from '../images/life/splitPainting.jpg';
import tools from '../images/life/tools.jpg';
import brendaClose from '../images/life/brendaClose.jpg';
import galeria9 from '../images/life/galeria9.jpg';
import cuadro1 from '../images/life/cuadro1.jpg';
import cuadro2 from '../images/life/cuadro2.jpg';
import flash from '../images/life/flash.JPG';
import galeria10 from '../images/life/galeria10.JPG';
import galeria11 from '../images/life/galeria11.JPG';
import galeria12 from '../images/life/galeria12.jpg';
import galeria13 from '../images/life/galeria13.jpg';
import galeria14 from '../images/life/galeria14.jpg';

import './about.css';
import Footer from '../components/footer';
import BgImgFancy from '../components/bgImgFancy';

export default function About() {

  return (
    <>
    <Centered>
      <div className="pageWidth" style={{marginBottom: 50}}>
        <div
          style={{
            backgroundImage: `url(${aboutBackground})`,
          }}
          className="aboutBackground"
        >
          <div className="aboutTitle">Brenda Fridman</div>
        </div>
      </div>
      <div className="whiteBackground">
        <div className="splitView pageWidth">
          <div className="paragraph">
            Brenda Fridman es una artista argentina con más de 20 años dedicados al diseño y al arte. Sus obras retratan momentos de cambio y esperanza de la humanidad. La serie “I have a dream” nos cuenta de muros derribados y sueños de cambio, partiendo del histórico discurso de Martin Luther King Jr. Más recientemente su obra habla de lo que Stefan Zweig llamó “momentos estelares”, retratando fechas que han marcado un antes y un después para la humanidad.
          </div>
          <div className="sideImage">
            <img src={brendaFridman} alt="muestra solidaria" />
          </div>
        </div>
      </div>
      <div className="grayBackground">
        <div className="splitView reverse pageWidth">
          <div className="paragraph">
            Trabaja con materiales naturales que van desde el óxido hasta arena procedente de playas del Mediterráneo y del Océano Atlántico Sur. Dentro del estilo abstracto, sus obras están llenas de simbolismos, mezclando realismo con expresiones urbanas como el esténcil y las pintadas con origen en el grafiti. Con estos recursos significa en su obra la condición actual de la sociedad, sus miedos, esperanzas y sueños. Sus cuadros fueron expuestos en galerías en Luxemburgo, Francia, Italia y más recientemente en Madrid, España.
          </div>
          <div className="sideImage">
            <img src={brendaPainting2} alt="muestra solidaria" />
          </div>
        </div>
      </div>
      <div className="whiteBackground">
        <div className="splitView pageWidth">
          <div className="paragraph">
            Su obra refleja su experiencia vital durante las diferentes etapas de su vida en ciudades tan diversas como su natal Buenos Aires, Boston y Seattle en los Estados Unidos, en Ámsterdam, Holanda, Luxemburgo y actualmente en Madrid.<br />
            <br />
            <br />
            Inició su carrera en Argentina, donde estudió diseño primero en la Universidad de Buenos Aires y luego se especializó en alta costura con Paco Jamandreu, quien fuera el diseñador personal de Eva Perón. Luego continuó sus estudios en Holanda e Inglaterra con el Amsterdam Fashion Institute y el London School of Fashion.
          </div>
          <div className="sideImage">
            <img src={brendaPainting3} alt="muestra solidaria" />
          </div>
        </div>
      </div>
      <div className="grayBackground">
        <div className="splitView reverse pageWidth">
          <div className="paragraph">
            Antes de dedicarse en forma exclusiva al arte, trabajó en empresas de moda como Levi's, basada en sus oficinas centrales para Europa en Ámsterdam.
            <br />
            <br />
            La obra sobre lienzo de Brenda Fridman se complementa con una serie de láminas numeradas donde sus pinturas son tratadas con medios digitales que anulan todos los matices usando la misma técnica con la que se comprimen los archivos de música. De esta forma, los infinitos tonos de grises que en la obra original van en un continuo desde el blanco hasta el mismo negro, se resumen en tan solo tres grises. La misma técnica es aplicada a todos los colores que componen el cuadro. Lo que queda es una obra con superficies planas y de alto contraste que sintetiza lo que es la original, donde solamente queda lo esencial.
          </div>
          <div className="sideImage">
            <img src={brendaClose} alt="muestra solidaria" />
          </div>
        </div>
      </div>
      <div style={{marginTop: 80, marginBottom: 20}}>
        <Footer soft/>
      </div>
    </Centered>
    </>
  )
}

