import { Redirect, useParams } from "react-router-dom";
import { getPaintingByName } from "../data/utils";

import PaintingDetails from "../components/paintingDetails";

import { useHistory } from "react-router-dom";
import Centered from "../components/centered";
import './painting.css';

import backIcon from "../images/back.png";

export default function Painting() {
  const history = useHistory();

  const { name } = useParams();

  const data = getPaintingByName(name);

  if (data == null) {
    return <Redirect to='/paintings' />;
  }

  return (
    <>
      <Centered>
        <div className="pageWidth">
          <div className='paintingBackButton' onClick={() => history.goBack()}>
            <img src={backIcon}></img>
            <div>Back</div>
          </div>
        </div>
      </Centered>
      <PaintingDetails painting={data}></PaintingDetails>
    </>
  );
}
