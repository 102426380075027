import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/home.js';
import Contact from './pages/contact.js';
import Paintings from './pages/paintings';
import Series from './pages/series.js';
import About from './pages/about.js';
import Painting from './pages/painting.js';

import NavBar from './components/navbar.js';
import Footer from './components/footer.js';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />

        <Switch>
          <Route path="/paintings">
            <Paintings />
          </Route>
          <Route path="/series">
            <Series />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/painting/:name">
            <Painting />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
