import {
  NavLink
} from "react-router-dom";

import './navbar.css';

import { useState } from 'react';

import Centered from "./centered";

import burgerIcon from "../images/burgerIcon.png";

export default function NavBar() {
  const [extend, setExtend] = useState(false);

  return (
    <Centered>
      <div className="navbar monitor pageWidth">
        <NavLink to="/" exact activeClassName="active" className="brand">
          <div>BRENDA FRIDMAN</div>
        </NavLink>
        <NavLink to="/paintings" activeClassName="active" className="option">
          <div>Selected paintings</div>
        </NavLink>
        <NavLink to="/series" activeClassName="active" className="option">
          <div>Series</div>
        </NavLink>
        <NavLink to="/about" activeClassName="active" className="option">
          <div>About</div>
        </NavLink>
        <NavLink to="/contact" activeClassName="active" className="option">
          <div>Contact</div>
        </NavLink>
      </div>

      <div className="navbar phone pageWidth">
        <div className="alwaysVisible">
          <NavLink to="/" exact activeClassName="active" className="brand" onClick={() => setExtend(false)}>
            <div>BRENDA FRIDMAN</div>
          </NavLink>
          <img src={burgerIcon} className="burger" onClick={() => setExtend(!extend)}></img>
        </div>
        <div className={"options" + (extend ? " visible" : "")}>
          <NavLink to="/paintings" activeClassName="active" className="option" onClick={() => setExtend(false)}>
            <div>Selected paintings</div>
          </NavLink>
          <NavLink to="/series" activeClassName="active" className="option" onClick={() => setExtend(false)}>
            <div>Series</div>
          </NavLink>
          <NavLink to="/about" activeClassName="active" className="option" onClick={() => setExtend(false)}>
            <div>About</div>
          </NavLink>
          <NavLink to="/contact" activeClassName="active" className="option" onClick={() => setExtend(false)}>
            <div>Contact</div>
          </NavLink>
        </div>
      </div>
    </Centered>
  );
}
