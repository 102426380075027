import Centered from "./centered";

import './footer.css';
import instagram from '../images/instagram.svg';

export default function Footer({soft}) {
  return (
    <Centered>
      <div className={'pageWidth footer' + (soft ? ' soft' : '')}>
        <div className="copyright">Copyright © {(new Date()).getFullYear()} Brenda Fridman. All Rights Reserved.</div>
        <div className="socialMedia">
          <a href="https://www.instagram.com/brendafridman/" target='_blank'>
            <img src={instagram}></img>
          </a>
        </div>
      </div>
    </Centered>
  );
}