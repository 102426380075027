import collections from './collections.json';
import paintings from './paintings.json';

export function getPaintingById(id) {
  return paintings.find(p => p.id == id);
}

export function getCollectionById(id) {
  return collections.find(c => c.id == id);
}

export function normalize(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "_");
}

export function getPaintingByName(name) {
  name = normalize(name);

  return paintings.find(p => {

    if (p.legacyNames) {
      if (p.legacyNames.map(n => normalize(n)).includes(name)) {
        return true;
      }
    }

    return normalize(p.name) == name;
  });
}

export function getCollectionByName(name) {
  name = normalize(name);

  return collections.find(c => normalize(c.name) == name);
}

export function resolvePaintingList(names) {

  return names.map(name => getPaintingByName(name));

}


