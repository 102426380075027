import React from 'react';
import ReactDOM from 'react-dom';
import Centered from '../components/centered';

import aboutBackground from '../images/about.JPG';
import behindGlass from '../images/life/behindGlass.JPG';
import muestraSolidaria from '../images/life/muestraSolidaria.jpg';
import brendaFridman from '../images/life/brendaFridman.jpg';
import modusOperandi from '../images/life/modusOperandi.jpg';
import galeria3 from '../images/life/galeria3.JPG';
import galeria4 from '../images/life/galeria4.jpg';
import galeria5 from '../images/life/galeria5.jpg';
import galeria6 from '../images/life/galeria6.jpg';
import galeria7 from '../images/life/galeria7.jpg';
import books from '../images/life/books.jpg';
import brendaPainting from '../images/life/brendaPainting.JPG';
import brendaPainting2 from '../images/life/brendaPainting2.JPG';
import brendaPainting3 from '../images/life/brendaPainting3.JPG';
import details1 from '../images/life/details1.jpg';
import details2 from '../images/life/details2.jpg';
import details3 from '../images/life/details3.jpg';
import details4 from '../images/life/details4.jpg';
import details5 from '../images/life/details5.jpg';
import details6 from '../images/life/details6.jpg';
import details8 from '../images/life/details8.jpg';
import galeria1 from '../images/life/galeria1.JPG';
import galeria2 from '../images/life/galeria2.JPG';
import impresionDigital from '../images/life/impresionDigital.jpg';
import splitPainting from '../images/life/splitPainting.jpg';
import tools from '../images/life/tools.jpg';
import brendaClose from '../images/life/brendaClose.jpg';
import galeria9 from '../images/life/galeria9.jpg';
import cuadro1 from '../images/life/cuadro1.jpg';
import cuadro2 from '../images/life/cuadro2.jpg';
import flash from '../images/life/flash.JPG';
import galeria10 from '../images/life/galeria10.JPG';
import galeria11 from '../images/life/galeria11.JPG';
import galeria12 from '../images/life/galeria12.jpg';
import galeria13 from '../images/life/galeria13.jpg';
import galeria14 from '../images/life/galeria14.jpg';
import BgImgFancy from './bgImgFancy';

function SquareImage({ src }) {
  return (
    <div className='paintingImageContainer'>
      <BgImgFancy
        src={src}
        className='paintingSquare'
      ></BgImgFancy>
    </div>
  );
}

const ThreeColumns = () => {
  const firstThird = [galeria3, books, details2, details5, details8, impresionDigital, galeria4, galeria9, flash];
  const secondThird = [galeria7, brendaPainting, details3, details6, galeria2, splitPainting, galeria5, cuadro1, galeria10];
  const thirdThird = [behindGlass, details1, details4, galeria1, tools, muestraSolidaria, modusOperandi, galeria12, galeria14];

  return (
    <div className='allPaintings'>
      <div className='column'>
        {firstThird.map(src => {
          return (
            <SquareImage src={src} />
          );
        })}
      </div>
      <div className='column'>
        {secondThird.map(src => {
          return (
            <SquareImage src={src} />
          );
        })}
      </div>
      <div className='column'>
        {thirdThird.map(src => {
          return (
            <SquareImage src={src} />
          );
        })}
      </div>
    </div>
  )
}

export default ThreeColumns


