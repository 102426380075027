import { useEffect, useState } from "react";

export default function BgImgFancy(props) {

  const [currentStyle, setCurrentStyle] = useState({
    backgroundImage: `url(https://furntech.org.za/wp-content/uploads/2017/05/placeholder-image.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  });

  let propsWithout = {...props};
  delete propsWithout.style;

  const customStyle = props.style || {};
  customStyle.backgroundImage = `url(${props.src})`;

  useEffect(() => {
    var image = new Image();
    console.log(customStyle);
    image.onload = () => {
      setCurrentStyle(customStyle);
    };
    image.src = props.src;
  }, []);

  return (
    <div {...propsWithout}
      style={currentStyle}
    ></div>
  )

}
