import "./paintingDetails.css";
import collections from "../data/collections.json";
import BgImgFancy from "./bgImgFancy";

export default function PaintingDetails({ painting, description }) {
  const collection = collections.find(c => c.id === painting.collection);

  return (
    <div className='paintingDetails'>
      <BgImgFancy
        className='paintingDetailsImage'
        src={`../paintings/${painting.id}.${painting.imageExtension}`}
      ></BgImgFancy>
      <div
        class={
          "paintingDetailsTitle" +
          (painting.sold
            ? " red circle"
            : painting.reserved
            ? " yellow circle"
            : "")
        }
      >
        <b>{painting.name}</b> - {painting.dimensions}
      </div>
      <div class='paintingDetailsCollection'>Series: {collection.name}</div>
      {description ? <div class='paintingDescription'>Description</div> : null}
    </div>
  );
}
