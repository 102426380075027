import React from "react";
import { Link } from "react-router-dom";
import Centered from "../components/centered";
import { getPaintingByName, normalize, resolvePaintingList } from "../data/utils";

import Footer from "../components/footer";

import paintingColumns from "./paintings.columns.json";
import "./paintings.css";

import background from "../images/dynamicBackgrounds/4.jpg";

import loadingGif from "../images/loading.gif";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ReactImageAppear from "react-image-appear";
import PaintingDetails from "../components/paintingDetails";

function PaintingImage({ data }) {
  return (
    <Link
      to={`/painting/${normalize(data.name)}`}
      className='image'
      style={{ textDecoration: "none" }}
    >
      <ReactImageAppear
        src={`../paintings/${data.id}.${data.imageExtension}`}
        animation='fadeIn'
        animationDuration='1s'
        loader={loadingGif}
        placeholder
      />
    </Link>
  );
}

export default function Paintings() {

  const paintings = resolvePaintingList([
    "The Great Filter I",
    "Tic tac",
    "Shift",
    "Back to intense blue",
    "Tablado",
    "Serendipity",
    "Back to colors",
    "Back to red",
    "I have a dream I",
    "Back to blue",
    "The girl and the mask",
    "Hope",
    "La calle de los cambios"
  ]);

  return (
    <>
      <Centered>
        <div className="pageWidth">
          <Carousel
            emulateTouch
            infiniteLoop
            showThumbs={false}
          >
            {
              paintings.map(painting => {
                return (
                  <PaintingDetails painting={painting} key={painting.id}></PaintingDetails>
                )
              })
            }
          </Carousel>
        </div>
      </Centered>
      <div style={{marginTop: "40px", marginBottom: "20px"}}>
        <Footer soft/>
      </div>
    </>
  );
}

/*

<div className='column'>
  {resolvePaintingList(paintingColumns[0]).map(paintingData => {
    return <PaintingImage data={paintingData} />;
  })}
</div>
<div className='column'>
  {resolvePaintingList(paintingColumns[1]).map(paintingData => {
    return <PaintingImage data={paintingData} />;
  })}
</div>

*/
