import React, { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Link, Route, Switch } from "react-router-dom";
import Centered from "../components/centered";
import {
  getCollectionByName,
  normalize,
  resolvePaintingList
} from "../data/utils";

import paintingColumns from "./paintings.columns.json";
import "./series.css";

import collections from "../data/collections.json";
import paintings from "../data/paintings.json";

import Footer from "../components/footer";
import BgImgFancy from "../components/bgImgFancy";

function PaintingImage({ data }) {
  let imgSrc = data.thumbnailImageExtension ? `../paintings/${data.id}t.${data.thumbnailImageExtension}` : `../paintings/${data.id}.${data.imageExtension}`;
  return (
    <div className='paintingImageContainer'>
      <Link
        to={`/painting/${normalize(data.name)}`}
        className='paintingImage'
        style={{ textDecoration: "none" }}
      >
        <div className='pTitle'>
          <b>{data.name}</b> - {data.dimensions}
        </div>

        <BgImgFancy
          src={imgSrc}
          className='paintingSquare'
        ></BgImgFancy>
      </Link>
    </div>
  );
}

function getSelectedCollection(p) {
  const { collection: collectioName } = p;

  let collection = collections[8];

  if (collectioName) {
    collection = getCollectionByName(collectioName);
  }

  return collection;
}

function Collection() {
  let collectionOrder = [10, 9, 8, 7, 1, 3, 2, 4, 5];

  let collection = getSelectedCollection(useParams());

  let selectedPaintings = paintings.filter(p => p.collection == collection.id);

  let firstThird = selectedPaintings.filter((p, i) => i % 3 == 0);
  let secondThird = selectedPaintings.filter((p, i) => i % 3 == 1);
  let thirdThird = selectedPaintings.filter((p, i) => i % 3 == 2);

  return (
    <Centered>
      <div className='pageWidth'>
        <div className='seriesTitleBar'>
          <div className='seriesBar'>
            {collectionOrder.map(cid => {
              const c = collections.find(c => c.id == cid);
              return (
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/series/${normalize(c.name)}`}
                >
                  <div
                    key={cid}
                    className='seriesOption'
                    style={{
                      color: collection.id == cid ? "#ff0000" : "black",
                      textDecoration: "none"
                    }}
                  >
                    {c.name}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className='allPaintings'>
          <div className='column'>
            {firstThird.map(paintingData => {
              return (
                <PaintingImage data={paintingData} key={paintingData.id} />
              );
            })}
          </div>
          <div className='column'>
            {secondThird.map(paintingData => {
              return (
                <PaintingImage data={paintingData} key={paintingData.id} />
              );
            })}
          </div>
          <div className='column'>
            {thirdThird.map(paintingData => {
              return (
                <PaintingImage data={paintingData} key={paintingData.id} />
              );
            })}
          </div>
        </div>
        <div style={{ marginTop: 40, marginBottom: 20 }}>
          <Footer soft />
        </div>
      </div>
    </Centered>
  );
}

export default function Series() {
  return (
    <>
      <Switch>
        <Route path='/series' exact>
          <Collection />
        </Route>
        <Route path='/series/:collection'>
          <Collection />
        </Route>
      </Switch>
    </>
  );
}
