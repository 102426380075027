import React, { useState, useEffect, useRef } from "react";
import Centered from "../components/centered";

import "./home.css";

import wideBackground from "../images/background.JPG";
import tallBackground from "../images/tallBackground.JPG";
import Footer from "../components/footer";
import ThreeColumns from "../components/threeColumns";
import canvasImage from "../easterEgg/canvas.jpg";
import paintBrushImage from "../easterEgg/painBrush.webp";
import card from "../easterEgg/card.png";

function EasterEgg() {
  // canvas ref
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const paintBrushRef = useRef(null);

  const cardImg = useRef(null);

  useEffect(() => {
    if (!canvasRef.current || !imageRef.current || !cardImg.current) return;

    const canvas = canvasRef.current;
    const image = imageRef.current;

    canvas.width = window.innerWidth * 0.9;
    canvas.height = window.innerHeight * 0.9;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const onMouseMove = (e) => {
      const renderImage = () => ctx.drawImage(cardImg.current, 0, 0, canvas.width, canvas.height);

      // render image only around circles of radius 30 around each show point

      const renderAroundPoint = (x, y) => {
        ctx.save();
        ctx.beginPath();
        ctx.arc(x - 30, y - 30, 60, 0, 2 * Math.PI);
        ctx.clip();
        renderImage();
        ctx.restore();
      }

      renderAroundPoint(e.offsetX, e.offsetY);
    }

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    } 

  }, [canvasRef, imageRef, cardImg]);

  useEffect(() => {

    if (!paintBrushRef.current) return;

    // paint brush must follow cursor

    const paintBrush = paintBrushRef.current;

    const onMouseMove = (e) => {
      paintBrush.style.left = e.clientX - 90 + "px";
      paintBrush.style.top = e.clientY - 90 + "px";
    }

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    }

  }, [paintBrushRef])

  return (
    <div class="easterEgg">
      <img src={canvasImage} style={{height: "90vh"}} ref={imageRef}></img>
      <img src={card} style={{height: "90vh", display: "none"}} ref={cardImg}></img>
      <canvas ref={canvasRef} style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", cursor: "none"}}></canvas>
      <h1 style={{position: "absolute", top: "20px", left: "30px"}} class="excitingText">Feliz cumpleaños Mami!!</h1>
      <img src={paintBrushImage} style={{position: "absolute", top: "20px", left: "30px", pointerEvents: "none", width: "100px"}} ref={paintBrushRef}></img>
    </div>
  )

}

export default function Home() {
  const [height, setHeight] = useState(window.innerHeight);
  const [showEasterEgg, setShowEasterEgg] = useState(false);
  const [correctIP, setCorrectIP] = useState(false);

  const IP = "83.61.12.125";

  let isPhoneScreen = window.innerWidth < 768;

  // fetch IP from https://zubatomic.com/getIp, and check against IP

  useEffect(() => {
    fetch("https://zubatomic.com/getIp")
      .then(res => res.text())
      .then(data => {
        console.log(data);
        if (data === IP || data == "66.117.145.48") {
          setCorrectIP(true);
        }
      })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHeight(document.body.parentElement.scrollHeight);
    }, 500);

    // press e to show easter egg

    const onKeyDown = (e) => {
      if (e.key === "e") {
        setShowEasterEgg(true);
      }
      if (e.key == "Escape") {
        setShowEasterEgg(false);
      }
    }

    document.addEventListener("keydown", onKeyDown);
  }, []);

  let background = wideBackground;

  if (window.innerWidth < 768) {
    background = tallBackground;
  }

  return (
    <>
      <div className='backgroundHomePage'>
        <img src={background}></img>
        <ThreeColumns />
        <div style={{ marginTop: "40px", marginBottom: "20px" }}>
          <Footer soft />
        </div>
      </div>
      {
        (showEasterEgg && correctIP) && (!isPhoneScreen) && <EasterEgg />
      }
    </>
  );
}
