import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Centered from '../components/centered';
import Footer from '../components/footer';

import brendaClose from '../images/life/brendaClose.jpg'

import './contact.css';

export default function Contact() {

  const formEmail = useRef(null);
  const formSubject = useRef(null);
  const formMessage = useRef(null);

  const [result, setResult] = useState(null);

  const submitContactForm = evt => {

    evt.preventDefault();

    if (result !== null) {
      return;
    }

    const endpoint = "https://32ajrtsb89.execute-api.eu-west-1.amazonaws.com/prod/brendaFridmanContactForm";

    const email = formEmail.current.value;
    const subject = formSubject.current.value;
    const message = formMessage.current.value;

    var lambdaRequest = new Request(endpoint, {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify({email, subject, message})
    });

    // Call the Fetch API to make our request
    fetch(lambdaRequest)
      // This is where you can handle errors. This is just an example, so we won't cover that.
      .then(response => setResult(true))
      .catch(err => setResult(false));

  };

  return (
    <Centered>
      <div className="whiteBackground">
        <div className="splitView reverse pageWidth">
          <div className="paragraph makeBig">
            <form onSubmit={submitContactForm}>
              <h1>Contact</h1>
              <div className="label">Your email so we can reply to you</div>
              <input className="fancyInput" spellCheck={false} ref={formEmail}></input>
              <div className="label">Email subject</div>
              <input className="fancyInput" ref={formSubject}></input>
              <div className="label">Email content</div>
              <textarea className="fancyArea" ref={formMessage}></textarea>
              <button className="fancyButton">Send email</button>
            </form>
            {result === true ?
              <div className="notification success">
                Your email has been sent! We will get back to you as soon as possible :)
              </div>
            : result === false ?
            <div className="notification error">
              There was an error sending your email. Please try again later! Sorry for the inconveniance.
            </div>
            : null}
          </div>
          <div className="sideImage hideWhenSmall">
            <img src={brendaClose} alt="muestra solidaria" />
          </div>
        </div>
      </div>
      <div style={{marginBottom: "20px"}}>
        <Footer soft/>
      </div>
    </Centered>
  )
}

